import React from 'react'
import PropTypes from 'prop-types'
import { graphql, Link } from "gatsby";

import Layout from '../components/layout/Layout'

import { SendinblueFormHtml } from "../components/home/SendinblueFormHtml";
import HomeTitle from "../components/home/HomeTitle";
import CtaLink from "../components/home/CtaLink";

export const IndexPageTemplate = ({}) => (
  <div>
    <div className="is-flex is-justify-content-center content container"
         style={{position: "center", justifyContent: "center", alignItems: "center", marginBottom: 0}}
    >
      <SendinblueFormHtml/>
    </div>

    <div className="is-flex is-justify-content-center container">
      <HomeTitle/>
    </div>

    {/*<div className="is-flex is-justify-content-center section content container" style={{paddingTop : 0, paddingBottom : 0, marginBottom : 0}}>*/}
    {/*  <h2 className="has-text-weight-bold" style={{textAlign : "left", justifyContent : "start", width : "100%", fontSize : "2rem", marginBottom : "0.5rem"}}>Réservez nos différents types d'ateliers en ligne :</h2>*/}
    {/*</div>*/}
    {/*<div className="is-flex is-justify-content-center section content container" style={{paddingTop : 0, paddingBottom : 0}}>*/}
    {/*  <div  className="content is-offset-1" style={{ marginBottom : "3rem"}}>*/}
    {/*    <div className="columns is-mobile is-flex is-multiline is-flex-mobile" style={{gap : "1rem", display : "flex", marginTop : "1rem", paddingLeft : "1rem", paddingRight : "1rem"}}>*/}
    {/*      <CtaLink label={"Atelier modelage"} to={"ateliers-modelage"}/>*/}
    {/*      <CtaLink label={"Peinture sur céramique"} to={"cafe-ceramique"}/>*/}
    {/*      <CtaLink label={"Ateliers divers"} to={"autre-ateliers"}/>*/}
    {/*      <CtaLink label={"Ateliers enfants"} to={"ateliers-enfants"}/>*/}
    {/*    </div>*/}
    {/*  </div>*/}
    {/*</div>*/}
  </div >
)

IndexPageTemplate.propTypes = {
  imageAcceuil: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  image0: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  intro: PropTypes.shape({
    blurbs: PropTypes.array,
  }),
}

const IndexPage = ({ data }) => {
  const { frontmatter } = data.markdownRemark

  return (
    <Layout>
      <IndexPageTemplate
        imageAcceuil={frontmatter.imageAcceuil}
        image0={frontmatter.image0}
        intro={frontmatter.intro}
      />
    </Layout>
  )
}

IndexPage.propTypes = {
  data: PropTypes.shape({
    markdownRemark: PropTypes.shape({
      frontmatter: PropTypes.object,
    }),
  }),
}

export default IndexPage

export const pageQuery = graphql`
  query IndexPageTemplate {
    markdownRemark(frontmatter: { templateKey: { eq: "index-page" } }) {
      frontmatter {
        title
        image0 {
          alt
          image {
            childImageSharp {
              gatsbyImageData(width: 1280, quality: 92, layout: CONSTRAINED)
            }
          }
          maxWidth
        }
        intro {
          blurbs {  
            imageBlurb {
              childImageSharp {
                  gatsbyImageData(width: 440, quality: 92, layout: CONSTRAINED)
              }
            }
            text
            description
            url
          }
        }
      }
    }
  }`



