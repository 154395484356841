import React, { useEffect, useState } from "react";

import '../../style/sendinBlueStyle.css'

import { useIsMobile } from "../../hooks/useIsMobile";
export const SendinblueFormHtml = () => {

  const {isMobile} = useIsMobile();


  const [email, setEmail] = useState("");
  const [subscriptionSucceed, setSubscriptionSucceed] = useState(false);
  const [subscriptionSucceedTimeoutFinished, setSubscriptionSucceedTimeoutFinished] = useState(false);
  const [subscriptionError, setSubscriptionError] = useState(false);
  const [emailError, setEmailError] = useState(false);
  const [subscribedToNewsletter, setSubscribedToNewsletter] = useState('true');

  useEffect(() => {
      if(!localStorage.hasOwnProperty("subscribedToNewsletter")) {
        setSubscribedToNewsletter('null')
      } else {
        setSubscribedToNewsletter(localStorage.getItem('subscribedToNewsletter'))
      }
    }
    , [])

  const validate = (email) => {
    return /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(email);
  }

  const handleChange = (event) => {
    setEmail(event.target.value)
  }

  const signUp = async (e) => {
    e.preventDefault();

    const isValidated = validate(email)
    if(isValidated) {
      setEmailError(false)
      const fetch = require('node-fetch');
      const url = 'https://api.sendinblue.com/v3/contacts';
      const options = {
        method: 'POST',
        headers: {
          accept: 'application/json',
          'content-type': 'application/json',
          'api-key': process.env.GATSBY_SENDINBLUE_API_KEY
        },
        body: JSON.stringify({
          email: email,
          locale: "fr",
          html_type: "simple",
          listIds: [2]
        })
      };
      fetch(url, options)
        .then(res => res.json())
        .then(json => {
          if(json.id) {
            setSubscriptionSucceed(true)
            localStorage.setItem('subscribedToNewsletter', 'true')
            setSubscribedToNewsletter('true')
            setTimeout(() => {
              setSubscriptionSucceedTimeoutFinished(true)
            }, 5000)
          } else {
            setSubscriptionError(true)
          }
        })
        .catch(err => {
          console.error('error:' + err)
          setSubscriptionError(true)
        });
    } else {
      setEmailError(true)
    }
  }

  return (
    <>
      {/*<div className={"sib-form " + " " +  isMobile ? " flexColumn" : null}*/}
      <div className={"sib-form "}
           style={{
             textAlign: "left",
             backgroundImage: `url(../img/v2/Apageaccueil-min.JPG)`,
             backgroundSize: "cover",
             backgroundRepeat: "no-repeat",
             backgroundPositionX : "50%",
             backgroundPositionY : "20%",
             backgroundAttachment : "scroll",
             width: "100%",
             height: "600px",
             display : "flex",
             // flexDirection : isMobile ? "column" : "row",
             alignItems : "center",
             // justifyContent : "space-around"
             fontFamily: "Montserrat, sans-serif",
           }}
      >
        <div id="sib-form-container" className="sib-form-container" style={{
          paddingLeft : isMobile ? "0px" : "2rem",
          paddingTop : "10rem"
        }}>
          {/*<div id="sib-form-container" className="sib-form-container" style={{ marginTop: 100 }}>*/}
          {subscriptionSucceed && !subscriptionSucceedTimeoutFinished &&
          <div className="form-completed">
            <div id="success-message" className="sib-form-message-panel"
                 style={{
                   fontSize: "16px",
                   textAlign: "left",
                   color: "#085229",
                   backgroundColor: "#e7faf0",
                   borderRadius: "1px",
                   borderColor: "#13ce66",
                   maxWidth: "350px",
                   display: "flex",
                   fontFamily: "Montserrat, sans-serif",

                 }}
            >
              <div className="sib-form-message-panel__text sib-form-message-panel__text--center">
                <svg viewBox="0 0 512 512" className="sib-icon sib-notification__icon">
                  <path
                    d="M256 8C119.033 8 8 119.033 8 256s111.033 248 248 248 248-111.033 248-248S392.967 8 256 8zm0 464c-118.664 0-216-96.055-216-216 0-118.663 96.055-216 216-216 118.664 0 216 96.055 216 216 0 118.663-96.055 216-216 216zm141.63-274.961L217.15 376.071c-4.705 4.667-12.303 4.637-16.97-.068l-85.878-86.572c-4.667-4.705-4.637-12.303.068-16.97l8.52-8.451c4.705-4.667 12.303-4.637 16.97.068l68.976 69.533 163.441-162.13c4.705-4.667 12.303-4.637 16.97.068l8.451 8.52c4.668 4.705 4.637 12.303-.068 16.97z" />
                </svg>
                <span className="sib-form-message-panel__inner-text">
                          Votre inscription est confirmée.
                      </span>
              </div>
            </div>
          </div>
          }
          {(subscribedToNewsletter === 'null' || subscribedToNewsletter === 'false') &&
          <div id="sib-container" className="sib-container--medium sib-container--vertical"
               style={{
                 textAlign: "left",
                 color: "black",
                 backgroundColor: "#eed7c5",
                 borderRadius: "1px",
                 borderWidth: "0px",
                 borderColor: "#C0CCD9",
                 maxWidth: "455px",
                 padding: "12px"

               }}
            // style="text-align:left; background-color:rgba(148,83,65,1); max-width:350px; border-radius:1px; border-width:0px; border-color:#C0CCD9; border-style:solid;"
          >
            <form id="sib-form"
                  onSubmit={signUp}
            >
              <div style={{ padding: "0px 0" }}>
                <div className="sib-form-block"
                     style={{ fontSize: "16px", textAlign: "left", fontWeight: "700", color: "black", backgroundColor: "transparent", marginBottom:"0.3rem" }}>
                  <p>INSCRIVEZ VOUS À LA NEWSLETTER FORMA</p>
                </div>
              </div>
              <div style={{ padding: "0px 0" }}>
                <div className="sib-form-block"
                     style={{ fontSize: "15px", textAlign: "left", color: "black", backgroundColor: "transparent", marginBottom:"0.2rem" }}
                >
                  <div className="sib-text-form-block">
                    <p>Chaque mois, retrouvez l'ensemble de nos actualités : ateliers, évènements, et plus encore.</p>
                  </div>
                </div>
              </div>
              <div style={{ padding: "0px 0" }}>
                <div className="sib-input sib-form-block">
                  <div className="form__entry entry_block">
                    <div className="form__label-row ">
                      <div className="entry__field">
                        <input className="input" type="text" id="EMAIL" name="EMAIL" autoComplete="off" placeholder="Adresse mail" data-required="true"
                               required value={email} onChange={handleChange} />
                      </div>
                    </div>
                    <label className="entry__error entry__error--primary"
                           style={{
                             fontSize: "16px",
                             textAlign: "left",
                             color: "#661d1d",
                             backgroundColor: "#ffeded",
                             borderRadius: "1px",
                             borderColor: "#ff4949",
                             maxWidth: "350px"
                           }}
                    >
                    </label>
                  </div>
                </div>
              </div>
              <div style={{ padding: "0px 0" }}>
                <div className="sib-captcha sib-form-block">
                  <div className="form__entry entry_block">
                    <div className="form__label-row ">
                      {/*<script>*/}
                      {/*  {`*/}
                      {/*                        function handleCaptchaResponse() {*/}
                      {/*                            var event = new Event('captchaChange');*/}
                      {/*                            document.getElementById('sib-captcha').dispatchEvent(event);*/}
                      {/*                        }*/}
                      {/*                     `}*/}
                      {/*</script>*/}
                      {/*<div className="g-recaptcha sib-visible-recaptcha" id="sib-captcha" data-sitekey="6Lf2FpkgAAAAAKnUvExOoKBqtzd63tI2mvXu_cBc"*/}
                      {/*     data-callback="handleCaptchaResponse"></div>*/}
                    </div>
                    <label className="entry__error entry__error--primary"
                           style={{
                             fontSize: "16px",
                             textAlign: "left",
                             color: "#661d1d",
                             backgroundColor: "#ffeded",
                             borderRadius: "1px",
                             borderColor: "#ff4949",
                             maxWidth: "350px"
                           }}
                    >
                    </label>
                  </div>
                </div>
              </div>
              <div style={{ padding: "0px 0" }}>
                <div className="sib-form-block" style={{ textAlign: "left" }}>
                  <button className="sib-form-block__button sib-form-block__button-with-loader "
                          style={{
                            fontSize: "16px",
                            textAlign: "left",
                            fontWeight: "700",
                            color: "white",
                            backgroundColor: "#a25840",
                            borderRadius: "1px",
                            borderWidth: "0px",
                            borderColor: "#ebd2bc",
                            maxWidth: "350px"
                          }}
                          type="submit">
                    <svg className="icon clickable__icon progress-indicator__icon sib-hide-loader-icon" viewBox="0 0 512 512">
                      <path
                        d="M460.116 373.846l-20.823-12.022c-5.541-3.199-7.54-10.159-4.663-15.874 30.137-59.886 28.343-131.652-5.386-189.946-33.641-58.394-94.896-95.833-161.827-99.676C261.028 55.961 256 50.751 256 44.352V20.309c0-6.904 5.808-12.337 12.703-11.982 83.556 4.306 160.163 50.864 202.11 123.677 42.063 72.696 44.079 162.316 6.031 236.832-3.14 6.148-10.75 8.461-16.728 5.01z" />
                    </svg>
                    Je veux des infos !
                  </button>
                </div>
              </div>
              <input type="text" name="email_address_check" value="" className="input--hidden" />
              <input type="hidden" name="locale" value="fr" />
            </form>
          </div>
          }

          {subscriptionError &&
          <div id="error-message" className="sib-form-message-panel"
               style={{
                 fontSize: "16px",
                 textAlign: "left",
                 color: "#661d1d",
                 backgroundColor: "#ffeded",
                 borderRadius: "1px",
                 borderColor: "#ff4949",
                 maxWidth: "350px",
                 display: "flex",
                 marginTop: "10px"
               }}
          >
            <div className="sib-form-message-panel__text sib-form-message-panel__text--center">
              <svg viewBox="0 0 512 512" className="sib-icon sib-notification__icon">
                <path
                  d="M256 40c118.621 0 216 96.075 216 216 0 119.291-96.61 216-216 216-119.244 0-216-96.562-216-216 0-119.203 96.602-216 216-216m0-32C119.043 8 8 119.083 8 256c0 136.997 111.043 248 248 248s248-111.003 248-248C504 119.083 392.957 8 256 8zm-11.49 120h22.979c6.823 0 12.274 5.682 11.99 12.5l-7 168c-.268 6.428-5.556 11.5-11.99 11.5h-8.979c-6.433 0-11.722-5.073-11.99-11.5l-7-168c-.283-6.818 5.167-12.5 11.99-12.5zM256 340c-15.464 0-28 12.536-28 28s12.536 28 28 28 28-12.536 28-28-12.536-28-28-28z" />
              </svg>
              <span className="sib-form-message-panel__inner-text">
                          Nous n&#039;avons pas pu confirmer votre inscription.
                      </span>
            </div>
          </div>
          }

          {emailError &&
          <div id="error-message" className="sib-form-message-panel"
               style={{
                 fontSize: "16px",
                 textAlign: "left",
                 color: "#661d1d",
                 backgroundColor: "#ffeded",
                 borderRadius: "1px",
                 borderColor: "#ff4949",
                 maxWidth: "350px",
                 display: "flex",
                 marginTop: "10px"
               }}
          >
            <div className="sib-form-message-panel__text sib-form-message-panel__text--center">
              <svg viewBox="0 0 512 512" className="sib-icon sib-notification__icon">
                <path
                  d="M256 40c118.621 0 216 96.075 216 216 0 119.291-96.61 216-216 216-119.244 0-216-96.562-216-216 0-119.203 96.602-216 216-216m0-32C119.043 8 8 119.083 8 256c0 136.997 111.043 248 248 248s248-111.003 248-248C504 119.083 392.957 8 256 8zm-11.49 120h22.979c6.823 0 12.274 5.682 11.99 12.5l-7 168c-.268 6.428-5.556 11.5-11.99 11.5h-8.979c-6.433 0-11.722-5.073-11.99-11.5l-7-168c-.283-6.818 5.167-12.5 11.99-12.5zM256 340c-15.464 0-28 12.536-28 28s12.536 28 28 28 28-12.536 28-28-12.536-28-28-28z" />
              </svg>
              <span className="sib-form-message-panel__inner-text">
                          Adresse email invalide.
                      </span>
            </div>
          </div>
          }
        </div>
      </div>
    </>
  )
}

