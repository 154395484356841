import React from "react";
import { useIsMobile } from "../../hooks/useIsMobile";


const HomeTitle = () => {

  const { isMobile } = useIsMobile();

  return (
    <div style={{
      color: "white",
      marginBottom: "2rem",
      display: "flex",
      justifyContent: "center",
      flexDirection: "column",
      alignItems: "center",
    }}>
      {isMobile ?
        <img src={"../img/v2/banniere-acceuil-mobile.png"}></img>
        :
        <img src={"../img/v2/banniere-acceuil.jpg"}></img>
      }
    </div>
  )
}


export default HomeTitle